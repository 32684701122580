// app/src/pages/Community.tsx
import { useWallet } from "@solana/wallet-adapter-react";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  Trophy,
  Users,
  Star,
  Flame,
  Share2,
  MessageCircle,
  Info,
} from "lucide-react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from "recharts";
import { LoadingState } from "../components/LoadingState";
import { ErrorState } from "../components/ErrorState";
import { InviteSection } from "../components/InviteSection";
import { statsApi } from "../lib/api";
import { Activity } from "../lib/api/activity";
import { PlatformStats } from "../lib/api/types";
import PointsInfoModal from "../components/PointsInfoModal";
import StreakIndicator from "../components/StreakIndicator";
import ActivityFeed from "../components/ActivityFeed";
import PointsBreakdown from "../components/PointsBreakdown";

interface ExtendedPlatformStats {
  points: number;
  rank: number;
  engagements: number;
  streak: number;
  history: {
    date: string;
    points: number;
  }[];
}

interface UserData {
  totalPoints: number;
  rank: number;
  platformStats: {
    TELEGRAM: ExtendedPlatformStats;
    DISCORD: ExtendedPlatformStats;
    TWITTER: ExtendedPlatformStats;
  };
  recentActivity: Activity[];
}

export default function Community() {
  const { publicKey } = useWallet();
  const navigate = useNavigate();
  const [userData, setUserData] = useState<UserData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [isPointsModalOpen, setIsPointsModalOpen] = useState(false);

  // Helper function to transform API platform stats to extended stats
  const transformPlatformStats = (
    apiStats: PlatformStats | undefined,
    defaultRank: number
  ): ExtendedPlatformStats => ({
    points: apiStats?.points ?? 0,
    rank: defaultRank,
    engagements: apiStats?.interactions ?? 0,
    streak: 0, // Default streak since API doesn't provide this
    history: [] // Will be populated by generateHistory
  });

  useEffect(() => {
    const fetchUserData = async () => {
      if (!publicKey) {
        navigate("/register");
        return;
      }

      try {
        const stats = await statsApi.getUserStats(publicKey.toString());
        
        // Generate example history data if not provided by API
        const generateHistory = (startPoints: number) => {
          return Array.from({ length: 7 }, (_, i) => ({
            date: new Date(Date.now() - i * 86400000).toLocaleDateString('en-US', { month: 'short', day: 'numeric' }),
            points: Math.max(0, startPoints - Math.floor(Math.random() * 20) * i)
          })).reverse();
        };
        
        // Transform API response to UserData format
        const transformedData: UserData = {
          totalPoints: stats.totalPoints,
          rank: stats.rank,
          platformStats: {
            TELEGRAM: {
              ...transformPlatformStats(stats.platforms?.telegram, stats.rank),
              history: generateHistory(stats.platforms?.telegram?.points ?? 30)
            },
            DISCORD: {
              ...transformPlatformStats(stats.platforms?.discord, stats.rank + 5),
              history: generateHistory(stats.platforms?.discord?.points ?? 10)
            },
            TWITTER: {
              ...transformPlatformStats(stats.platforms?.twitter, stats.rank + 10),
              history: generateHistory(stats.platforms?.twitter?.points ?? 5)
            }
          },
          // Create dummy recent activity data since API doesn't provide it
          recentActivity: Array.from({ length: 5 }, (_, i) => ({
            id: String(Date.now() + i),
            userId: 'user123',
            username: 'user123',
            action: ['MESSAGE', 'QUALITY_POST', 'MENTION', 'FACT_SHARE', 'INVITE'][i % 5],
            platform: ['TELEGRAM', 'DISCORD', 'TWITTER'][Math.floor(Math.random() * 3)] as Activity['platform'],
            points: Math.floor(Math.random() * 10) + 1,
            timestamp: new Date(Date.now() - i * 3600000).toLocaleString()
          }))
        };
        
        setUserData(transformedData);
      } catch (error) {
        console.error("Error fetching user data:", error);
        setError("Failed to load user data");
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [publicKey, navigate]);

  if (loading) return <LoadingState />;
  if (error) return <ErrorState message={error} />;
  if (!userData) return null;

  // Count active platforms (platforms with points > 0)
  const getActivePlatformsCount = () => {
    return Object.values(userData.platformStats).filter(stats => stats.points > 0).length;
  };

  const StatCard = ({
    title,
    value,
    icon: Icon,
    color,
  }: {
    title: string;
    value: string | number;
    icon: React.ElementType;
    color: string;
  }) => (
    <div className="bg-black/50 backdrop-blur-lg p-6 rounded-lg border border-soless-blue/40">
      <div className="flex items-center justify-between mb-2">
        <h3 className="text-gray-300 font-medium">{title}</h3>
        <Icon className={`h-5 w-5 ${color}`} />
      </div>
      <p className="text-2xl font-bold text-white">{value}</p>
    </div>
  );

  const PlatformEngagement = ({
    platform,
    stats,
    platformKey,
  }: {
    platform: string;
    stats: ExtendedPlatformStats;
    platformKey: 'telegram' | 'discord' | 'twitter';
  }) => (
    <div className="bg-black/50 backdrop-blur-lg p-6 rounded-lg border border-soless-blue/40">
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-xl font-bold text-soless-blue">{platform}</h3>
        <div className="flex items-center space-x-2">
          <Flame
            className={stats.streak > 0 ? "text-orange-400" : "text-gray-600"}
            size={16}
          />
          <span className="text-sm text-gray-400">{stats.streak}d streak</span>
        </div>
      </div>

      <div className="grid grid-cols-3 gap-4 mb-6">
        <div>
          <p className="text-gray-400 text-sm">Points</p>
          <p className="text-white text-lg font-medium">{stats.points}</p>
        </div>
        <div>
          <p className="text-gray-400 text-sm">Rank</p>
          <p className="text-white text-lg font-medium">#{stats.rank}</p>
        </div>
        <div>
          <p className="text-gray-400 text-sm">Engagements</p>
          <p className="text-white text-lg font-medium">{stats.engagements}</p>
        </div>
      </div>

      <StreakIndicator currentStreak={stats.streak} platform={platformKey} />
      
      <div className="h-48 mt-4">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={stats.history}
            margin={{ top: 5, right: 5, left: 0, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" stroke="#1f2937" />
            <XAxis dataKey="date" stroke="#9ca3af" />
            <YAxis stroke="#9ca3af" />
            <Tooltip
              contentStyle={{
                backgroundColor: "rgba(0, 0, 0, 0.8)",
                border: "1px solid rgba(59, 130, 246, 0.4)",
              }}
            />
            <Line 
              type="monotone" 
              dataKey="points" 
              stroke={
                platformKey === 'telegram' ? '#3B82F6' : 
                platformKey === 'discord' ? '#6366F1' : 
                '#0EA5E9'
              } 
              strokeWidth={2}
              activeDot={{ r: 6 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );

  const getActivityDescription = (type: string): string => {
    const descriptions: Record<string, string> = {
      MESSAGE: "Keyword message",
      QUALITY_POST: "Quality post",
      MENTION: "Mentioning others",
      TEACHING_POST: "Teaching post",
      MUSIC_SHARE: "Music sharing",
      FACT_SHARE: "SOLess fact",
      STREAK_BONUS: "Streak bonus",
      COMMAND: "Command used",
      INVITE: "Invited member",
    };
    
    return descriptions[type] || type;
  };

  const ActivityItem = ({
    activity,
  }: {
    activity: Activity;
  }) => (
    <div className="flex items-center justify-between py-3 border-b border-soless-blue/20">
      <div className="flex items-center space-x-3">
        <div
          className={`w-8 h-8 rounded-full flex items-center justify-center bg-soless-blue/20`}
        >
          {activity.platform === "TELEGRAM" && (
            <MessageCircle size={16} className="text-blue-400" />
          )}
          {activity.platform === "DISCORD" && (
            <MessageCircle size={16} className="text-indigo-400" />
          )}
          {activity.platform === "TWITTER" && (
            <Share2 size={16} className="text-sky-400" />
          )}
        </div>
        <div>
          <p className="text-white text-sm font-medium">
            {getActivityDescription(activity.action)}
          </p>
          <p className="text-gray-400 text-xs">{activity.timestamp}</p>
        </div>
      </div>
      <div className="text-right">
        <p className="text-green-400 text-sm font-medium">+{activity.points}</p>
      </div>
    </div>
  );

  return (
    <div className="min-h-screen bg-black pt-16">
      <div className="w-full">
        <img
          src="/assets/images/winter-banner.jpg"
          alt="SOLess Banner"
          className="w-full h-auto"
        />
      </div>

      <div className="max-w-7xl mx-auto px-4 py-8">
        <div className="flex flex-col md:flex-row md:items-center md:justify-between mb-8">
          <div className="flex items-center gap-4">
            <h1 className="text-4xl font-bold text-soless-blue">
              Community Dashboard
            </h1>
            <button
              onClick={() => setIsPointsModalOpen(true)}
              className="flex items-center gap-2 text-soless-blue hover:text-blue-400"
            >
              <Info className="w-4 h-4" />
              <span>Scoring System</span>
            </button>
          </div>
          <InviteSection wallet={publicKey?.toString() || ""} />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
          <StatCard
            title="Total Points"
            value={userData.totalPoints}
            icon={Trophy}
            color="text-yellow-400"
          />
          <StatCard
            title="Global Rank"
            value={`#${userData.rank}`}
            icon={Star}
            color="text-purple-400"
          />
          <StatCard
            title="Active Platforms"
            value={getActivePlatformsCount()}
            icon={Users}
            color="text-soless-blue"
          />
          <StatCard
            title="Best Streak"
            value={Math.max(
              userData.platformStats.TELEGRAM.streak,
              userData.platformStats.DISCORD.streak,
              userData.platformStats.TWITTER.streak
            )}
            icon={Flame}
            color="text-orange-400"
          />
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mb-8">
          <PlatformEngagement
            platform="Telegram"
            stats={userData.platformStats.TELEGRAM}
            platformKey="telegram"
          />
          <PlatformEngagement
            platform="Discord"
            stats={userData.platformStats.DISCORD}
            platformKey="discord"
          />
          <PlatformEngagement
            platform="Twitter"
            stats={userData.platformStats.TWITTER}
            platformKey="twitter"
          />
        </div>
        
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-8">
          <PointsBreakdown 
            telegramPoints={userData.platformStats.TELEGRAM.points}
            discordPoints={userData.platformStats.DISCORD.points}
            twitterPoints={userData.platformStats.TWITTER.points}
          />
          <ActivityFeed activities={userData.recentActivity} />
        </div>

        <div className="bg-black/50 backdrop-blur-lg rounded-lg border border-soless-blue/40 p-6">
          <h2 className="text-2xl font-bold text-soless-blue mb-4">
            Your Recent Activity
          </h2>
          <div className="space-y-1">
            {userData.recentActivity && userData.recentActivity.length > 0 ? (
              userData.recentActivity.map((activity, index) => (
                <ActivityItem key={index} activity={activity} />
              ))
            ) : (
              <p className="text-gray-400 py-4 text-center">No recent activity to display. Start engaging to earn points!</p>
            )}
          </div>
        </div>
      </div>
      <PointsInfoModal isOpen={isPointsModalOpen} setIsOpen={setIsPointsModalOpen} />
    </div>
  );
}
