import { apiClient } from "./client";

export interface Activity {
  id: string;
  userId: string;
  username: string;
  action: string;
  platform: 'TELEGRAM' | 'DISCORD' | 'TWITTER';
  points: number;
  timestamp: string;
}

export const activityApi = {
  getRecentActivity: async (): Promise<Activity[]> => {
    const response = await apiClient.get('/api/activity/recent');
    return response.data;
  }
};
