// src/lib/api/client.ts
import axios, { AxiosRequestConfig } from "axios";

declare module 'axios' {
  export interface AxiosRequestConfig {
    retry?: boolean;
    _retryCount?: number;
  }
}

const API_URL = import.meta.env.VITE_API_URL || "http://localhost:3001";

export const apiClient = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 15000, // 15 second timeout
  timeoutErrorMessage: "Request timed out - please try again"
});

// Add retry functionality
apiClient.interceptors.response.use(undefined, async (err) => {
  const { config } = err;
  if (!config || !config.retry) {
    return Promise.reject(err);
  }

  config._retryCount = config._retryCount || 0;

  if (config._retryCount >= 3) {
    return Promise.reject(err);
  }

  config._retryCount += 1;

  const backoff = new Promise((resolve) => {
    setTimeout(() => resolve(null), 1000 * config._retryCount);
  });

  await backoff;
  return apiClient(config);
});

// Configure default retry behavior
apiClient.defaults.retry = true;

// Add wallet address header if available
apiClient.interceptors.request.use((config) => {
  const walletAddress = localStorage.getItem('walletAddress');
  if (walletAddress) {
    config.headers['x-wallet-address'] = walletAddress;
  }
  return config;
});

// Handle response errors
apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    // Log the full error for debugging
    console.error("API Error:", {
      config: error.config,
      response: error.response,
      message: error.message,
    });

    if (error.response) {
      // Server responded with error status
      if (error.response.status === 401) {
        localStorage.removeItem("token");
        window.location.href = "/login";
      }

      // Create error with status code in message for easier checking
      const message = error.response.data?.error || error.response.statusText;
      return Promise.reject(new Error(`${error.response.status}: ${message}`));
    } else if (error.request) {
      // Request was made but no response received
      console.error("No response received:", {
        timeout: error.code === 'ECONNABORTED',
        request: error.request
      });
      const message = error.code === 'ECONNABORTED' 
        ? "Request timed out - please check your connection and try again"
        : "No response from server - please try again";
      return Promise.reject(new Error(message));
    } else {
      // Something happened in setting up the request
      console.error("Request setup error:", error.message);
      return Promise.reject(error);
    }
  }
);

// Export types for use in components
export type ApiResponse<T> = {
  data: T;
  status: number;
  message?: string;
};

export type ApiError = {
  status: number;
  message: string;
  errors?: Record<string, string[]>;
};
