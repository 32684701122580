import { Link } from "react-router-dom";
import { ArrowRight } from "lucide-react";

const Home = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-6">
      {/* Banner and logo stacked vertically */}
      <div className="flex flex-col items-center mb-0">
        <img
          src="/assets/images/WordBanner.png"
          alt="SOLess Banner"
          className="w-full max-w-4xl mx-auto h-auto"
        />
        <img
          src="/assets/images/HomePageLogo.png"
          alt="SOLess Logo"
          className="h-72 w-auto mt-0"
        />
      </div>

      <div className="text-center mb-8">
        {" "}
        {/* Reduced spacing */}
        <p className="text-2xl text-gray-300 mb-4">
          {" "}
          {/* Reduced margin */}
          Welcome to the closed-system short squeeze. Three intricately
          connected platforms powered by burning $SOUL(s).
        </p>
      </div>

      {/* Liquidity Staking Banner */}
      <Link to="/liquidity">
        <div className="bg-black/30 border border-cyan-500/50 rounded-xl p-6 mb-8 hover:border-cyan-500 transition-colors cursor-pointer">
          <h2 className="text-2xl md:text-3xl font-bold text-cyan-500 text-center mb-2">
            Stake liquidity now and grab the last chance to get SOUL at the presale price
          </h2>
          <p className="text-gray-300 text-center text-lg">
            Pairs will go live at the same time as SOUL! You've never staked pair like this before.
          </p>
          <div className="flex items-center justify-center mt-4 text-cyan-500 hover:text-cyan-400">
            <span className="text-sm font-semibold">Start Staking</span>
            <ArrowRight className="ml-2 h-4 w-4" />
          </div>
        </div>
      </Link>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {" "}
        {/* Reduced gap */}
        {/* Info boxes with reduced spacing */}
        <div className="bg-black/30 p-6 rounded-xl border border-z">
          {" "}
          {/* Reduced padding */}
          <h3 className="text-2xl font-bold mb-4 text-soless-blue">
            SOLess Swap
          </h3>
          <p className="text-gray-300">
            SOLess Swap allows users to pay gas fees in any listed token – even
            meme coins – eliminating the need for SOL to cover transaction
            costs. By doing this we can tap into Solana's $11B Meme Coin Market.
          </p>
        </div>
        <div className="bg-black/30 p-8 rounded-xl border border-soless-blue/40">
          <h3 className="text-2xl font-bold mb-4 text-soless-blue">SOLspace</h3>
          <p className="text-gray-300">
            SOLspace is more than just a social network; it's a decentralized
            ecosystem where ownership, authenticity, and community come
            together. Say goodbye to data exploitation and platform control.
          </p>
        </div>
        <div className="bg-black/30 p-8 rounded-xl border border-soless-blue/40">
          <h3 className="text-2xl font-bold mb-4 text-soless-blue">SOLarium</h3>
          <p className="text-gray-300">
            SOLarium is a dedicated vault and marketplace for NFT art and social
            content created on SOLspace. When users mint NFTs, a portion of the
            minting price is used to buy SOLess tokens.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Home;
